import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Box, Badge, Button, Stack, Flex, Alert, AlertIcon } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { axios } from '../lib/axios-client'
import { useAuth } from '../context/auth'
import { OrderGroup } from '../types/order_group'
import { OrderGroupStatus } from '../constants/order_group'
import { OrderBadgeEnum, OrderStatusEnum } from '../constants/order'
import { getChargeText } from '../utils'

export default function Index() {
	const router = useRouter()
	const { id } = router.query
	const { isAuthenticated } = useAuth()
	const [orderGroup, setOrderGroup] = useState<OrderGroup | null>(null)
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<string | null>(null)

	useEffect(() => {
		const fetchOrderGroup = async () => {
			try {
				const res = await axios.get(`${process.env.REST_ENDPOINT}/driver/api/v1/order_groups/${id}`)
				setOrderGroup(res.data)
			} catch (e: any) {
				if (e.response.status === 401) {
					setError('この注文を閲覧する権限がありません')
				} else {
					setError('予期せぬエラーが起きました')
				}
			}
		}

		isAuthenticated && fetchOrderGroup()
	}, [isAuthenticated, id])

	const handleComplete = async () => {
		try {
			setIsLoading(true)
			await axios.patch(`${process.env.REST_ENDPOINT}/driver/api/v1/order_groups/${id}`, {
				status: OrderGroupStatus.DELIVERED,
			})
			router.push('/')
		} catch (e: any) {
			setError('予期せぬエラーが起きました')
		} finally {
			setIsLoading(false)
		}
	}

	if (error) {
		return (
			<Alert status="error" pt={16}>
				<AlertIcon />
				{error}
			</Alert>
		)
	}

	return (
		orderGroup && (
			<Box>
				<Box pt={16} pb={20}>
					<Box fontWeight="bold" p={4}>
						<Stack>
							<Flex>
								<Box width={20}>氏名</Box>
								<Box flex={1}>{orderGroup.name} 様</Box>
							</Flex>
							<Flex alignItems="center">
								<Box width={20}>配達先</Box>
								<Box flex={1}>
									<Box mr={1}>
										{orderGroup.city}
										{orderGroup.street_name}
									</Box>
									<Button
										colorScheme="teal"
										size="xs"
										mr={2}
										onClick={() => {
											navigator.clipboard.writeText(orderGroup.city + orderGroup.street_name)
										}}
									>
										コピー
									</Button>
									<Button
										colorScheme="teal"
										size="xs"
										rightIcon={<ExternalLinkIcon />}
										onClick={() => {
											window.open(
												`https://maps.google.com?q=${orderGroup.city + orderGroup.street_name}`
											)
										}}
									>
										マップを開く
									</Button>
								</Box>
							</Flex>
							{orderGroup.note && (
								<Flex alignItems="center">
									<Box width={20}>メモ</Box>
									<Box flex={1}>{orderGroup.note}</Box>
								</Flex>
							)}
							<Flex>
								<Box width={20}>電話番号</Box>
								<Box flex={1}>{orderGroup.tel}</Box>
							</Flex>
							<Flex justifyContent="space-between">
								<Box width={20}>配達料</Box>
								<Box flex={1}>{orderGroup.delivery_fee.toLocaleString()}円</Box>
							</Flex>
							<Flex justifyContent="space-between">
								<Box width={20}>金額合計</Box>
								<Box flex={1}>{orderGroup.price.toLocaleString()}円</Box>
							</Flex>
						</Stack>
					</Box>
					<Box p={4}>
						<Stack spacing={4}>
							{orderGroup.orders.map((order, index) => (
								<Box key={index} p={4} shadow="lg" rounded="lg">
									<Box fontWeight="bold" width="full">
										<Flex alignItems="center" justifyContent="space-between">
											<Box>{order.shop.title}</Box>
											<Badge
												fontSize="md"
												colorScheme={OrderBadgeEnum[order.status]}
												variant="outline"
											>
												{OrderStatusEnum[order.status]}
											</Badge>
										</Flex>
										<Box color="gray" fontWeight="normal" flex="1">
											{order.shop.address}
										</Box>
									</Box>
									<Box>
										{order.line_items.map((lineItem, index) => (
											<Box key={index}>
												<Flex justifyContent="space-between">
													<Box>
														<Box>
															{lineItem.product.title} x {lineItem.quantity}
														</Box>
														<Box fontSize="0.8rem" color="gray.500">
															{lineItem.customizations
																.map(
																	(customization) =>
																		customization.option.title + getChargeText(customization.price)
																)
																.join(' / ')}
														</Box>
													</Box>
													<Box>{lineItem.price.toLocaleString()}円</Box>
												</Flex>
											</Box>
										))}
									</Box>
								</Box>
							))}
						</Stack>
					</Box>
				</Box>
				<Button
					position="fixed"
					bottom={0}
					borderColor="gray.200"
					isFullWidth
					bgColor="teal"
					color="#fff"
					borderRadius={0}
					height={16}
					onClick={handleComplete}
					disabled={isLoading || orderGroup.status === 'delivered'}
				>
					配達完了
				</Button>
			</Box>
		)
	)
}
